/**
 *  Initialisation de l'environement et composant JS
 */

var selTab  = [];
var PRIMARY_KEY_SEP = "+";
var menu_height = 36;
var oldFav = $('link[rel="shortcut icon"]').attr('href');;


/**
 * Message d'erreur générique, sur erreur javascript
 * 
 */
 
window.onerror = function(msg, url, line, col, error) {
   var extra  = !col ? '' : '\ncolumn: ' + col;
       extra += !error ? '' : '\nerror: ' + error;

   alert("[Erreur Javascript]\n\n " + msg + "\n\nurl: " + url + "\nligne: " + line + extra);

   // TODO: Appel ajax pour mail d'erreur ? 

   return true;
};


/**
 * Oouverture d'un nouvel onglet à la racine, qd touche alt-w
 */
$(document).keydown(function(event) {
  if (!event.altKey){ return true; }
  if (event.which == 87) {
    event.stopPropagation();
    event.preventDefault();    
    window.open( window.location.origin, "_blank");
  }
});

/**
 * comportement fancybox (rotation)
 */
$(document).on('onInit.fb', function (e, instance) {
  if ($('.fancybox-buttons').find('#rotate_button').length === 0) {
    $('.fancybox-buttons').prepend(`'<button id="rotate_button" class="fancybox-button" sign="1"  title="Pivoter l'image à droite"><i class="fa fa-repeat"></i></button>`);
    $('.fancybox-buttons').prepend(`'<button id="rotate_button" class="fancybox-button" sign="-1" title="Pivoter l'image à gauche"><i class="fa fa-undo"></i></button>`);
  }

  $('.fancybox-buttons').on('click', '#rotate_button', function () {
    let rot_count = parseInt($('.fancybox-slide--complete').find('img').attr('rot_count') || 0) + 1;
    sign = parseInt($(this).attr('sign'));
    var n = 90 * sign * rot_count;
    $('.fancybox-slide--complete').find('img').css('transform', 'rotate(' + n + 'deg)');
    $('.fancybox-slide--complete').find('img').attr('rot_count', rot_count++)
  });
});


/**
 * Comportement Ajax
 */


// on catch les debut et fin des appels ajax
$(document).ajaxStart(function () {
    $(document).find(".js_itab_loading").show();
    $('link[rel="shortcut icon"]').attr('href', '/media/images/wait_flat_gray.gif');
});

$(document).ajaxComplete(function (e, xhr, settings) {
    $(document).find(".js_itab_loading").hide();
    if(settings.url != '/pusher') {
      last_ajax_url = settings.url;
      tick = moment();
    }
    $('link[rel="shortcut icon"]').attr('href', oldFav);
});

$(document).ajaxError(function (e, jqXHR, ajaxSettings, thrownError)
{
    if (jqXHR.status === 0 || jqXHR.status == 200) {
      
    } // code 412 dédié aux actions Ajax, avec message utilisateur spécifique (exclu des mails)
    else if (jqXHR.status == 412) {
      $.toast({icon: 'error', text: jqXHR.responseJSON, hideAfter : false });
    }
    else if (jqXHR.status == 418) {
      // I'm a teapot (utilisé pour les erreurs gérées en exception par le système (handler.php))
      // $.toast({icon: 'error', text: '(ajax) Erreur de traitement', hideAfter : false });
    } 
    else if (jqXHR.status == 422) {
      // formulaire invalide (géré en détail)
    } 
    else if (jqXHR.status == 401) {
      $.toast({icon: 'error', text: 'Session expirée', hideAfter : false });

      $.confirm({
        columnClass: 'col-md-4 col-md-offset-4',
        title: 'Session expirée',
        content: 'Recharger la page, après une nouvelle identification ?',
        buttons: {
          OK: {
            btnClass: 'btn-blue',
            action: function() {
             location.reload(true); 
              return;
            }
          },              
          Annuler: function() {
            return;
          }

        }
      });

    } 
    else if (jqXHR.status == 404) {
      $.toast({icon: 'error', text: '(ajax) Page non trouvée 404'});
    } 
    else if (jqXHR.status == 500) {
      $.toast({icon: 'error', text: '(ajax) Erreur système 500'});  
    } 
    else if (jqXHR.statusText === 'parsererror') {
      $.toast({icon: 'error', text: '(ajax) Erreur de décodage Json'});  
    } 
    else if (jqXHR.statusText === 'timeout') {
      $.toast({icon: 'error', text: '(ajax) Timeout'});  
    } 
    else {
      $.toast({icon: 'error', text: '(ajax) erreur non traitée: '+jqXHR.statusText });  
    }
  
});


/**
 * Gestion évènement global app (autofocus, tooltip, ...)
 */

$(document).ready(function() {

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });
  
  if (typeof pusher !== 'undefined') {
    if(!pusher) $(".js_itab_pusher").show();
  }

  // on cache les options principales du menu sans sous option
  $('#main-menu .dropdown-menu').each(function(ind, elem) {
    if(!$(elem).find('li').length) {
      $(elem).closest('.dropdown').hide();
    }
  });

  $('#main-menu .dropdown-menu li').each(function(ind, elem) {
    if( $(elem).attr('role') == 'separator' && (!$(elem).prev().length || !$(elem).next().length || $(elem).next().attr('role') == 'separator')) {
      $(elem).hide();
    }
  });

  // Gestion attribut autofocus sur modal
  $(document).on('shown.bs.modal', function (e) {
    var modal = $(e.target);
    
    // on prend en compte le premier input, non readonly, non caché
    var input = modal.find('input:not(".date_pick_custom"):not(".date_pick"):not([readonly]):not([type="hidden"])').first();

    // si on est pas sur un selctize ou si le selectize est vide, on donne le focus
    if(!input.closest('.selectize-input').length && input.hasClass('date_pick_custom') && input.hasClass('date_pick')) {
      input.focus();
    }

  });

  $(document).on('click', '#js_add_home', function (event) {
    let href = window.location.href;
    let module_name = $('#title_module').text().trim() || href.split("/").filter(Boolean).pop();
    let home_favs = localStorage.getDbObject("_home_favs", 'default') || [];

    if(_.find(home_favs, {'href': href})) {
      alert('page déjà ajoutée aux favoris');
    }
    else {
      $.confirm({
        title: '<span style="color: gray">Ajouter cette page aux favoris ?</span>',
        content: `
          <b>${module_name}</b> : ${href}
              <div class='top-10'><sub class='text-muted'>Libellé du favoris</sub>
              <div class='input-group'><input id="fav_name" class='form-control'></div>
        `,
        onContentReady: function () {
          $("#fav_name").val(module_name);
        },
        buttons: {
          OK: {
            btnClass: 'btn-blue',
            action: function() {
              home_favs.push({href: href, name: $("#fav_name").val() || module_name });
              localStorage.setDbObject("_home_favs", home_favs, 'default');
              if(typeof v_home != 'undefined') v_home.home_favs = home_favs;
            }
          },
          Annuler: function() {
            return;
          }
        }
      });
     
    }
  });

  // qtip - basé sur la class tips et title
  $(document).on('mouseenter', '.tips[title]', function (event) {
    var position = { my: 'bottom center', at: 'top center', 'viewport': $(window), 'adjust': {'method': 'flip flip'} };
    var tip_pos  = $(this).attr('tip_pos');
    var tip_hide = $(this).attr('tip_hide');
    var item_id  = $(this).attr('id');

    if( tip_pos ) {
      switch(tip_pos) {
        case 'top':
          break;
        case 'left':
          position = {  my: 'center right', at: 'left center' };          
          break;        
        case 'right':
          position = {  my: 'center left', at: 'center right' };          
          break;        
        case 'bottom':
          position = {  my: 'top center', at: 'bottom center' };          
          break;
      }
    }

    $(this).qtip({
        id: item_id,
        position: position,
        style: 'qtip-bootstrap  qtip-rounded qtip-shadow',
        overwrite: false, 
        hide: { event: (tip_hide?tip_hide:'mouseleave') },
        show: {
            event: event.type, 
            ready: true, 
            delay: 500,
            solo: true
        }
    });
  });

  /**
    * [description] gestion d'un toggle sur la visu mot de passe
    */
  $(document).on('click', '.toggle_show_password', function() {

        var control = $(this);
        var icon  = control.find('i') ;
        var field = control.closest('.input-group').find('input');


        if (field.attr('type') == 'password') {
            field.attr('type', 'text');
            icon.removeClass('glyphicon-eye-close').addClass('glyphicon-eye-open');
        } else {
            field.attr('type', 'password');
            icon.removeClass('glyphicon-eye-open').addClass('glyphicon-eye-close');
        }
  })

  /**
   * [description] Copie le contenu du champs associé dans le clipboard
   */
  $(document).on('click', '.copy_clip', function() {
    item = $(this);
    to_copy = item.attr('to_copy');
    if (!to_copy) to_copy = $(this).closest('.input-group').find('input, textarea').val();
    
    navigator.clipboard.writeText(to_copy);
  });

  /**
   * [description] Auvre le contenu d'un lien dans une popup
   */
  $(document).on('click', '.link_to_popup', function(e) {

    // si ctrl click on suit le lien par défaut de href
    if (e.ctrlKey) return true;

    e.preventDefault();
    let href = $(this).attr('href_popup');

    $.dialog({
      title: 'Documentation',
      content: `url:${href}` ,
    });

  });


  /**
   *  Vide l'ensemble des champs d'une form
   *  
   * @return {[type]} [description]
   */
  $(document).off('click', 'button[id^="reset"]');
  $(document).on('click', 'button[id^="reset"]', function(e) {
    e.preventDefault();
    let form = $(this).closest('form');      
    form.clearForm();
  });

  //!! non utilisé en direct, éventuellement pour debug ou exemple pour bouton de sauvegarde
  //!! géré maintenant sur event click des bouton submit des form search
  /**
   *  event généric sur les bouton avec id dump_form
   */
  $(document).off('click', 'button[id^="dump_form"]');
  $(document).on('click', 'button[id^="dump_form"]', function(e) {
    e.preventDefault();
    let form = $(this).closest('form');  
    let data = form.dump_form(mnemo_subkey());
    $(this).trigger('dump_form', data);
  });

  /**
   * event généric sur les bouton avec id load_form
   */
  $(document).off('click', 'button[id^="load_form"]');
  $(document).on('click', 'button[id^="load_form"]', function(e) {
    e.preventDefault();
    let form = $(this).closest('form');
    let formData = form.load_form(mnemo_subkey());
    $(this).trigger('load_form', formData);
  });


  bindFormComponents(); 
  bindLinkGlobalForm();
  bindLinkExternalForm();
});