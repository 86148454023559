/**
 * Fonctions utilitaires js, pouvant servire un peu partout
 */

// extension de lodash 
_.move = function(array,fromIndex,toIndex){
    array.splice(toIndex, 0, array.splice(fromIndex, 1)[0] );
}

/**
 * Geolock me, remplit, si possible, les variables globales (a changer) avec la geoloc de l'utilisateur
 */
var geolocMe_lat = null, geolocMe_lng = null;
function geolocMe(callback) {
  navigator.geolocation.getCurrentPosition(function(pos) {
    let crd = pos.coords;
    geolocMe_lat =  crd.latitude;
    geolocMe_lng =  crd.longitude;
    callback();
  }, 
  function(err) {
    geolocMe_lat = null;
    geolocMe_lng = null;
    callback();
    console.warn(`ERREUR (${err.code}): ${err.message}`);
  },
  {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  });
}

/**
 * Push file, demande au navigateur de charger le data dans un fichier
 */
function push_file(data, filename, type) {
  var exportedFilenmae = filename;
  var blob = new Blob([data], { type: type });
  
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
  } 
  else {
  
    var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFilenmae);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
    }
  }
}

/**
 * retourn l'encodage 64 de l'image pointée par url
 * @param {string} url chemin vers l'image doit être full qualified
 * @param {function} callback fonction appelée lorsque l'image est chargée et convertie
 */
function toDataURL(url, cb_param, callback) {
  var httpRequest = new XMLHttpRequest();
  httpRequest.onload = function() {
      var fileReader = new FileReader();
        fileReader.onloadend = function() {
            callback(cb_param, fileReader.result);
        }
        fileReader.readAsDataURL(httpRequest.response);
  };
  httpRequest.open('GET', url);
  httpRequest.responseType = 'blob';
  httpRequest.send();
}

/**
*  Génère un avatar 48x48 avec les initiales d'un prénom nom
 * @param {string} text 
 * @param {*} foregroundColor 
 * @param {*} backgroundColor 
 * @returns 
 */
function generateAvatar(text = '', size='250', foregroundColor = "#768ea5", backgroundColor = "#e9eefb") {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const myNames = text.split(" ");
  const initials = myNames.shift().charAt(0) + (myNames.length?myNames.pop().charAt(0):'');
  let nameInitials =initials.toUpperCase();

  // Pour Pierre :D 
  if(nameInitials == "PD") nameInitials = "PiDu";

  canvas.width = size;
  canvas.height = size;

  context.fillStyle = backgroundColor;
  context.fillRect(0, 0, canvas.width, canvas.height);

  context.font = "bold "+size*0.4+"px Segoe UI";
  context.fillStyle = foregroundColor;
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(nameInitials, canvas.width / 2, canvas.height / 2 );
  return canvas.toDataURL("image/png");

}



/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

  function post_blank(path, parameters) {
    var form = $('<form  target="_blank"></form>');

    form.attr("method", "post");
    form.attr("action", path);

    $.each(parameters, function(key, value) {
        if ( typeof value == 'object' || typeof value == 'array' ){
            $.each(value, function(subkey, subvalue) {
                var field = $('<input />');
                field.attr("type", "hidden");
                field.attr("name", key+'[]');
                field.attr("value", subvalue);
                form.append(field);
            });
        } else {
            var field = $('<input />');
            field.attr("type", "hidden");
            field.attr("name", key);
            field.attr("value", value);
            form.append(field);
        }
    });
    $(document.body).append(form);
    form.submit();
}



// gestion des object dans le localStorage. set et get
Storage.prototype.setObject = function(key, value) {
    this.setItem(key, JSON.stringify(value));
}

Storage.prototype.getObject = function(key) {
    var value = this.getItem(key);
    return value && JSON.parse(value);
}


// notif d'erreur pour appel ajax
function toastAjaxError(message) {
  text = "Problème d'appel serveur";
  if (message) text = text + ' :<br>' + message;
  $.toast({ heading: 'Erreur', icon: 'error', text : text, hideAfter : false });
}

/** 
 * Check les required de la form et retourne son clone si valide, sinon false
 */
function clone_with_validation(form) {

  form.find('.form-group').removeClass('has-error');
  form.find('span.field_error').remove();

  var _form = form.clone(true);

  _form.find("select.selectize").each(function() {
   if($(this).val() === '__blank__') { $(this).val(''); }
  })

  _form.find('input.mask').each(function(index, el) {
    el.value = $(el).cleanVal();
  });
  
  if (!_form[0].checkValidity()) {
    return false;
  }

  return _form;
}


// permet d'afficher le résultat d'un lien dans la modal global
// appel depuis bootgrid grace au onclick javascript, cfr : grid_formatter: modal_url
function openInGlobalModal(item, event) {

  event.preventDefault();

  url = $(item).attr('href');
  if(!url) return false;

  $.ajax({
    url: url,
    type: 'get'
  })
  .done(function(data) {
    $("#modal_global_form").find('#edit_form_body').html(data);
    $("#modal_global_form").find('.modal-title').html('Photos de la campagne');
    $("#modal_global_form").modal();
  })
  .fail(function(data) {
    toastAjaxError(data.responseJSON);
  });

};

// gestion par défaut des drop down avec et sans appel ajax
function emptySelectize(select) {
  select.trigger('option_clear');
  select.loadedSearches = {};
  select.userOptions = {};
  select.options = select.sifter.items = {};
  select.lastQuery = null;
  select.clear();
}

function clearSelByName(formId, link, value) {
  _.forEach(link.split(','), function(alink, key) {
    var child = $('form#'+formId).find("select.selectize[name="+alink.replace( /(:|\.|\[|\]|,|=|@)/g, "\\$1" )+"]");
    var childId = child.attr('id');
    var selChild = selTab[formId][childId][0].selectize;

    selChild.trigger('item_remove'); 
    emptySelectize(selChild);
    selTab[formId][childId][0].selectize.sub_key = value;
    if(value == null || value == '__blank__') {
      selChild.disable();            
    }
    else {
      selChild.enable();            
    }
  });  
}

function update_color() {
  $(".color_pick").each(function(ind, tag) {
    var target = $(tag).attr('color_target');
    var attr = $(tag).attr('color_attr');

    if(target && attr) {
      $(tag).closest('form').find("#"+target).css(attr, $(tag).find('input').val());
    }
  });  
}



// bind de la gestion des evenement sur les composant d'une form (date, select, mask, input change)
function bindFormComponents() {

  // on applique la gestion de scroll qui va bien sur les div avec .auto-scroll
  $(document).find('.auto-scroll').each(function(index, el) {
    var auto_hide = false;
    if($(el).hasClass('scroll-ah')) auto_hide = true;

         if($(el).hasClass('scroll-vh')) $(el).vhscroll(auto_hide);
    else if($(el).hasClass('scroll-v'))  $(el).vscroll(auto_hide);
    else if($(el).hasClass('scroll-h'))  $(el).hscroll(auto_hide);
  });

  bindColorPicker();
  update_color();
  bindDatepicker();
  bindSelectize();
  bindMask();
  bindInputChange();
  bindFormRequired();
  bindFormShare();
  bindFormSearchMemo();

}

function bindColorPicker() {
  $('form').find('.color_pick').colorpicker({
      customClass: 'colorpicker-2x',            
      sliders: {
          saturation: {
              maxLeft: 150,
              maxTop: 150
          },
          hue: {
              maxTop: 150
          },
          alpha: {
              maxTop: 150
          }
      }
  }).on('changeColor.colorpicker', function(event){
    update_color();
  });

}

// event sur le change d'une form search pour lancer un comptage des résultats
function bindSearchCount(form, ent_name, action) {

  // PRE SUBMIT: Affichage message d'attente avant de faire le vrai submit (search)
  $(form).find("#submit, #submit_default").click(function(e) {
    if(action) $(form).attr('action', action)  
    
    var _form = clone_with_validation($(this).closest('form'));
    if(_form) {
      $("#count_msg").html("Chargement des "+ent_name+"...");
    } 
    else {
      $("#count_msg").html("");
    }

    if(searchCountAjax) {
      searchCountAjax.abort();
      searchCountAjax = null;
    }

  });

  // ON CHANGE: Appel d'une route de count sur l'event on change de l'ensemble des champs du critère
  $(form).on('change', function(event) {
    if(action) $(form).attr('action', action)  

    var _form = clone_with_validation($(this));
    if(!_form) {
      $("#count_msg").html("");
      return true;
    }
 
    $("#count_msg").html("Comptage des "+ent_name+"...");

    if(searchCountAjax) {
      searchCountAjax.abort();
      searchCountAjax = null;
    }
    searchCountAjax = $.ajax({
      type: _form.attr('method'),
      url: _form.attr('action'),
      data: _form.serialize()+'&count=true&result=json',
      statusCode: {
        422: function (response) {
                if (response.responseJSON !== undefined) { /* gestion du retour du test sur la variable $search_rules */
                  var msg = []
                  _.forEach(response.responseJSON, function(value, key) {
                    msg.push(value);
                  });

                  $("#count_msg").html("Pas de comptage, " + msg.join(' '))
                } else if (validation && (typeof validation == "function")) { /* aucune idée pourquoi on fait ce test et d'où vient la variable validation, laisse au cas où */
                  $("#count_msg").html("Pas de comptage, formulaire invalide")
                }
             }
      }
    }).done(function(data) {
      data = $.parseJSON(data);
      $("#count_msg").html("Nombre de "+ent_name+" : <span id='js_qte_row'>"+(data.total?data.total:0)+"</span>" )

      if(data.total > 5000) {
        $("#js_qte_row").css('color', 'red');
        $("#js_qte_row").css('font-weight', 'bold');
        $("#js_qte_row").append('&nbsp;<i class="fa fa-exclamation-triangle fa-bs tips" title="Chargement extrêmement long"></i>');
      } 
      else if(data.total > 2500) {
        $("#js_qte_row").css('color', 'red');
        $("#js_qte_row").css('font-weight', 'bold');
        $("#js_qte_row").append('&nbsp;<i class="fa fa-info-circle fa-bs tips" title="Chargement très long"></i>');
      }
      else if(data.total > 1000) {
        $("#js_qte_row").css('color', 'orange');
        $("#js_qte_row").css('font-weight', 'bold');
        $("#js_qte_row").append('&nbsp;<i class="fa fa-info-circle fa-bs tips" title="Chargement long"></i>');
      } 
      else if(data.total > 500) {
        $("#js_qte_row").css('font-weight', 'bold');
      } else {
        $("#js_qte_row").css('color', '');
        $("#js_qte_row").css('font-weight', 'normal');
      }


    }).fail(function(data) {
      // $("#count_msg").html("Erreur de comptage")
    });

  });

}

// on push les champs de contact vers gotham sidekick
function bindFormShare() {

  $(document).off('click', '.js_sidekick');
  $(document).on('click', '.js_sidekick', function(e) {

    var form = $(this).closest(".modal-dialog").find('form');

    var data = {};

    data.phone_name     = form.find("#lastname").val() + ' ' + form.find("#firstname").val();
    data.phone_mail     = form.find("#email").val();
    data.phone_num      = form.find("#tel").val();
    data.phone_gsm      = form.find("#gsm").val();
    data.phone_comment  = form.find("#comments").val();

    data.phone_name = data.phone_name.trim();

    $.ajax({
      url: '/pusher',
      type: 'POST',
      dataType: 'json',
      data: {
              channel : 'phone_'+user_email,
              event: 'call',
              message: data,
            }
    })
    .done(function(data) {    
      $.toast({icon: 'info', text: 'Contact envoyé au téléphone'});
    })
    .fail(function() {
      console.log("error");
    })

  });

}


// gestion des event lié à la mémorisation des critère de recherche !!!
function bindFormSearchMemo() {

  setTimeout(() => {
    // on charge ce qu'on trouve en mnemo dans les form search trouvée
    if($("[id^=form_search]").length) $("[id^=form_search]").load_form('default');
  }, 0);

  // on se colle sur l'event click du submit (sinon on arrive trop tard)
  $(document).on('click', "[id^=form_search] #submit, [id^=form_search] #search", function(e) {
    // dump systémaqtique du contenu dans défault
    $(this).closest('form').dump_form('default');
    // !! dump dans une version si précisée - Pour le moment pas de sauvegarde auto 
    // $(this).closest('form').dump_form(mnemo_subkey());
  });

}


// gestion des champs required côté js, si demandé par la class req_validation 
function bindFormRequired() {

    // désavtive la validation html5 
    $("form.js_validation").attr('novalidate', true);

    // gestion des champ required
    $("form.js_validation").on('submit', function(e) {

      var swError = false;
      var oErrors = {};
      var form = $(this);

      $("[required='required']").each(function(index, el) {
        if( !$(el).val() ) {
          oErrors[$(el).attr('id')] = [ "Le champ est obligatoire." ];
          swError = true;
        }
      });

      // utilise la même méthode d'affichage que celle utilisée lors d'un retour submit laravel
      if(swError) {
        e.preventDefault();
        form.showFormErrors(oErrors);
      }

    })

}

// gestion d'évènement lors de la modification d'un champ, appel ajax d'une route def. ds les attr du champs et gestion du retour
function bindInputChange() {

  var ajax_change = function() {
    var item = $(this);
    var formId  = item.closest('form').attr('id');
    if (!item.val()) return;

    var item_id = item.attr('id');
    var ajax_return = item.attr('ajax_return');
    var ajax_route = item.attr('ajax_route');
    var ajax_route_suffix = item.attr('ajax_route_suffix');
    if (ajax_route_suffix) ajax_route = ajax_route + '/' + item.val();
    var ajax_link = item.attr('ajax_link');
    if (ajax_link !== undefined) ajax_link = ajax_link.split(' ');
    var ajax_param = item.attr('ajax_param');

    // par défaut on prend la valeur de l(item, sinon on s'appuie sur ajax_param qui doit contenir les id des item de form dont on prendra le val())
    var key_val = '';
    if(!ajax_param) {
      key_val = item.val(); 
    }
    else {
      params = ajax_param.split('|');
      $.each(params, function(idx, tag_id) {
        key_val += (key_val?PRIMARY_KEY_SEP:"")+item.closest('form').find("#"+tag_id).val();
      });
    }
    
    ajax_route = ajax_route.replace("#id", key_val);
    _.forEach(ajax_link, function(alink, key) {
      item.closest('form').find('#' + alink).prop('disabled', true);     
    });
    $.ajax({
      url: ajax_route
    })
    .done(function(data) {
      _.forEach(ajax_link, function(alink, key) {
        item.closest('form').find('#' + alink).prop('disabled', false);     
      });
      $.each(ajax_return.split("|"), function(idx, action) {
        switch(action) {
          case 'input_addon':
            var input_rspan = item.closest('.input-group').find('span#rspan_'+item_id);

            if(!$(input_rspan).length) {
              item.after('<span class="input-group-addon" id="rspan_'+item_id+'"></span>');
              input_rspan = item.closest('.input-group').find('span#rspan_'+item_id);
            }

            $(input_rspan).text(data);
            break;

          case 'input_default':
            $.each($.parseJSON(data), function(field, value) { // parcours de tous les champs à disposition
              if (field != 'list') {
                var input_to_change = item.closest('form').find('#' + field);
                if (input_to_change.length && !input_to_change.val()) { // changement de la valeur si besoin
                  input_to_change.val(value);

                  // traitement supplémentaire dans le cas d'un select
                  if (input_to_change.hasClass("selectize")) {
                    var sel = selTab[formId][input_to_change.attr("id")][0].selectize;
                    sel.setValue(value);
                  }
                }
              }
            });             
            break;

          case 'input_poplist':
            // stockage des valeurs courantes des listes
            var currentValues = {};
            _.forEach(ajax_link, function(alink, key) {
              const form = item.closest('form');
              const formTag = '[id=' + form.attr( "id" ) + '][name=' + form.attr( "name" ) + ']';
              const field = item.closest('form').find('#' + alink);
              const fieldTag = '[id=' + field.attr( "id" ) + '][name=' + field.attr( "name" ) + ']';
              const href = location.hostname + location.pathname + location.search + location.hash;
              
              currentValues[alink] = localStorage.getItem(href+formTag+fieldTag);
            });

            // pop des listes
            _.forEach(ajax_link, function(alink, key) {
              // sélection du select à pop et réinitialisation
              var input_to_change = item.closest('form').find('#' + alink + '_list');
              var input_default_nolist = null;
              if (!input_to_change) // il n'y a pas deux inputs
                input_to_change = item.closest('form').find('#' + alink);
              else
                input_default_nolist = item.closest('form').find('#' + alink);

              var div_control = input_to_change.closest('div');
              if (input_to_change.attr("id"))
                var sel = selTab[formId][input_to_change.attr("id")][0].selectize;
              else
                var sel = selTab[formId][alink][0].selectize;
              emptySelectize(sel);

              // check des données
              dataJSON = $.parseJSON(data);
              if (dataJSON.default || dataJSON.list) {
                default_val = dataJSON.default;
                list = dataJSON.list;
                
                if (!list) {
                  div_control.addClass("invo");
                  if (input_default_nolist) input_default_nolist.removeAttr("readonly");
                  return;
                }

                // pop
                $.each(list.split('#'), function(idx, val) {
                  if (val) sel.addOption({ text: val, id: val });
                  if (default_val == val) default_val = null;
                });

                // gestion de la valeur par defaut
                if (default_val) { 
                  sel.addOption({ text: default_val, id: default_val });
                  sel.setValue(default_val);
                }
              } else {
                // pop
                $.each(dataJSON, function(idx, val) {
                  if (val) sel.addOption({ text: val.text, id: val.id });
                });

                if (currentValues[alink]) sel.setValue(currentValues[alink].split(','));

                if (!sel.length) {
                  div_control.addClass("invo");
                  if (input_default_nolist) input_default_nolist.removeAttr("readonly");
                  return;
                }
              }

              div_control.removeClass("invo");
              if (input_default_nolist) input_default_nolist.attr("readonly", true);
            });            
            break;

          case 'input_call_back':
            if(typeof window['ic_'+item_id] === 'function')
              window['ic_'+item_id](item, data);
            else
              console.error('ic_'+item_id+' not found')
            break;

          default:
            console.log(data);
        }
      });
    })
    .fail(function(data) {
    });

  }

  $(document).off('change', '.ajax');
  $(document).on('change', '.ajax', ajax_change);

  // ajout pour permettre le load sub selectize en click
  $(document).off('click', '.js_force_load');
  $(document).on('click', '.js_force_load', function() {
    let input = $(this).closest('.input-group').find('input');
    input.trigger('focus').val("   ").trigger("keyup");
  })

}

function bindMask() {
  var TelMaskBehavior = function (val) {
                          l = val.replace(/\D/g, '').length;
                          if (l < 10) return '00.00.00.00.00';
                          if (l === 10) return '00.00.00.00.00.9';
                          if (l === 13) return '0000.0.00.00.00.00.9';

                          return '00000.0.00.00.00.00';
                        },
  spOptions = { onKeyPress: function(val, e, field, options) {
                  field.mask(TelMaskBehavior.apply({}, arguments), options);
                },
                placeholder: "__.__.__.__.__",
                selectOnFocus: true
              };

  $('.mask_tel').mask(TelMaskBehavior, spOptions);
}

function bindDatepicker() {
  
  $('form').find('input.date_pick, input.hour_pick').each(function(ind, elem) {
    
    let date_format = "";
    if($(elem).hasClass('date_pick') && $(elem).closest(".input-group").find('span.input-group-addon i.glyphicon-calendar').length == 0) {
      date_format = "DD/MM/YYYY";
      $(elem).after('<span class="input-group-addon"><i class="glyphicon glyphicon-calendar"></i></span>');
    }
    
    if($(elem).hasClass('hour_pick') && $(elem).closest(".input-group").find('span.input-group-addon i.glyphicon-time').length == 0) {
      date_format = "HH:mm";
      $(elem).after('<span class="input-group-addon"><span class="glyphicon glyphicon-time"></span></span>');
    }
    
    if( $(elem).attr("date_format") ) date_format = $(elem).attr("date_format");  

    $(elem).attr('autocomplete', 'off');
    $(elem).datetimepicker({
      locale: 'fr',
      format: date_format,
      calendarWeeks: true,
      tooltips: fr_tooltip_datepick,
      showTodayButton: true,
      showClear: true,
      useCurrent: false,
    });
    
    // date picker ne déclenche pas l'évent change mais un event propriétaire dp.change
    $(elem).on('dp.change', function() {
      $('form').change();
      $(this).change();
    })

  });

}

function bindSelectize() {

  $('form').find("select.selectize").each(function(index, el) {
    var formId  = $(this).closest('form').attr('id');
    var selId   = $(this).attr('id');
    var base    = $(this).attr('base');
    var entity  = $(this).attr('entity');
    var sub     = $(this).attr('sub');
    var mincar  = $(this).attr('mincar');
    var multi   = $(this).attr('multiple');
    var can_add = $(this).attr('can_add')?true:false;

    if(!selId) {
      let msg = `Pas de sel ID pour : ${$(this).attr('name')} > mémo impossible`;
      $(document).trigger('admin_msg', msg);
      console.error(msg);
    }

    if(!mincar) mincar = 3;

    var curSel = $(this);

    if(!selTab[formId]) selTab[formId] = [];

    // console.log('bindSelectize', selId, $(this).attr('name'));

    // pas d'info base et entitié, donc pas d'apel ajax, on active juste le composant
    if(!base || !entity) {

      selTab[formId][selId] = $(this).selectize({             
        plugins: ['clear_selection', 'remove_button', 'auto_position'],        
        valueField: 'id',
        labelField: 'text',
        lockOptgroupOrder: true,
        create: can_add,
        render: {
          option_create: function(item, escape) {
            return '<div class="create"><b>Ajouter</b>... '+escape(item.input)+'</div>';
          },
          option: function(item, escape) {
            return '<div>'+item.text+'</div>';
          },
          item: function(item, escape) {
            return '<div>'+item.text+'</div>';
          }

        },
        allowEmptyOption: false,     
        onOptionAdd: function(value) {
          $(document).trigger( "optionAdd", [ value, selId ] );
        }, 
        onChange: function(value) {
          $(document).trigger( "itemChange", [ value, selId ] );
        },        
        onItemAdd: function(value, $item){
          var link = curSel.attr("link");
          // // si on a un lien entre select, on place l'id dans le select enfant
          if(link) {
            clearSelByName(formId, link, value);
          }
        },
        onItemRemove: function(value){
          var link = curSel.attr("link");
          // si on a un lien entre select, on reset l'enfant, et on le disable
          if(link) {
            clearSelByName(formId, link, null);
          }
        },

      });
    }
    // le tag précise une base et une entité, on gère le remplisage par appel ajax
    else {
      var xhr;

      selTab[formId][selId] = $(this).selectize({
        plugins: ['clear_selection', 'remove_button'],
        valueField: 'id',
        labelField: 'text',
        searchField: 'text',
        options: [],
        create: false,
        openOnFocus: true,
        allowEmptyOption: false,
        onChange: function(value) {
          $(document).trigger( "itemChange", [ value, selId ] );
        },
        onItemAdd: function(value, $item){
          var link = curSel.attr("link");

          // // si on a un lien entre select, on place l'id dans le select enfant
          if(link) {
            clearSelByName(formId, link, value);
          }
        },
        onItemRemove: function(value){
          var link = curSel.attr("link");
  
          // si on a un lien entre select, on reset l'enfant, et on le disable
          if(link) {
            clearSelByName(formId, link, null);
          }

          // on vide le cache et le query associé (pas génial, mais sinon la liste présente des éléments indésirables)
          if(!multi) emptySelectize(this);
        },
        load: function(query, callback) {
          if(!multi) emptySelectize(this);

          if (query.length < mincar) return; // callback();
          
          var url =  '/selectize/'+base+'/'+entity+'/'+query;

          // on check la présence d'une sub query            
          sub_query = this.sub_key;
          if(sub_query) {
            if (sub) sub_query = JSON.stringify({sq: sub_query, params: sub});
            url = url + '/' + sub_query;
          } else {
            if (sub) url = url + '/' + sub;
          }

          // on retrouve le label associé pour indication de chargement
          var lab = this.$control; 
          if(lab.find('.field_load').length == 0) lab.append("<span class='field_load'><img src='/media/images/wait_flat_gray.gif'></span>");

          // si appel en cours on abord
          xhr && xhr.abort();
          xhr = $.ajax({
              url: url,
              type: 'GET',
              dataType: 'json',
              error: function() {
                lab.find('.field_load').remove(); 
                callback();
              },
              success: function(res) {
                lab.find('.field_load').remove();
                callback(res); 
              }
          });
        },
        score: function() { // tri géré par la route
          return function(search) {
            return 1;
          }
        }
      });
    }
  });

  // Ajout d'option dans les select avec les valeurs spécifié dans le tag
  $('form').find("select.selectize").each(function(index, el) {
    var formId   = $(this).closest('form').attr('id');   
    var selId    = $(this).attr('id');
    var setId    = $(this).attr('setId');
    var setText  = $(this).attr('setText');
    var disable  = $(this).attr('disable');

    if(selId) {
      var sel = selTab[formId][selId][0].selectize;

      if (setId) {
        if (setText) {
          sel.addOption({ text: setText, id: setId });
        }
        sel.setValue(setId);
      }
      if (disable) sel.disable();

      const form = $("#"+formId);
      const field = $("#"+selId);
      const formTag = '[id=' + form.attr( "id" ) + '][name=' + form.attr( "name" ) + ']';
      const fieldTag = '[id=' + field.attr( "id" ) + '][name=' + field.attr( "name" ) + ']';
      const href = location.hostname + location.pathname + location.search + location.hash;
      
      if(localStorage.getItem(href+formTag+fieldTag) && localStorage.getItem(href+formTag+fieldTag) != 'null') {
        const ids = localStorage.getItem(href+formTag+fieldTag) ? localStorage.getItem(href+formTag+fieldTag).split(',') : [];
        const texts = localStorage.getItem(href+formTag+fieldTag+'_text') ? localStorage.getItem(href+formTag+fieldTag+'_text').split('|') : [];
        if(ids.length && texts.length) ids.forEach((val, ind) => {
          if(!sel.getItem(val).length && !sel.getOption(val).length) {
            sel.addOption({ text: texts[ind], id: val, selected: true });
            sel.addItem(val);
          }
        });
      }

    }

    $(this).removeAttr('setId');
    $(this).removeAttr('setText');

  });


  $("[id*='-selectized']").focusin(function() {
    let formId = $(this).closest('form').attr('id');
    let selectId = $(this).attr('id').replace('-selectized', '');
    let inValue = $(this).closest('.selectize').find('.item').attr('data-value');
    if(inValue == '__blank__')  {
      emptySelectize(selTab[formId][selectId][0].selectize);
    }
  });

}

function affGlobalForm(url, rights, title, master_id, force_form_id, callback) {

  let data = {};
  let masterId = master_id;
  try {
    masterId = JSON.parse(master_id);
  } catch (error) {
    masterId = master_id
  }

  // pas très beau pour le moment, 
  // on veut dans la mesure du possible passer le master_id à l'appel get, mais la norme dit uir get < 2048 car
  // du coup on test si le param > 1500 (laisse de la place pour la base de l'url, si pas trop long on le passe en param
  // historiquement on ne passait pas l'info au get
  if(typeof masterId !== 'undefined' && JSON.stringify(masterId).length < 1500) data = { master_id: masterId };

  $.ajax({
       url: url,
      type: 'get',
      data: data
    })
    .done(function(data) {
      $("#modal_global_form").injectFormModal(data, '#edit_form_body', rights);

      // si master_id de type array, on s'attend à un tableau associatif de champs/valeurs
      if(master_id instanceof Array) {
        $.each(master_id, function(ind, field) {
          $("#modal_global_form").find(field.id).val(field.val);
        });
      }
      // sinon comme avant.
      else {
        if(master_id) $("#modal_global_form").find('.master_id').val(master_id);
      }

      $("#modal_global_form").find('.modal-title').html(title);
      $("#modal_global_form").modal();

      form_id = force_form_id || $("#modal_global_form").find('form').attr('id');
      
      if (form_id) registerSubmit("#modal_global_form", form_id.replace("form_", ""), master_id, callback);      
    })
    .fail(function(data) {
      toastAjaxError(data.responseJSON);
    });
}

/**
 * [bindLinkGlobalForm Action d'ouverture de la modal #modal_global_form sur un lien de class .link_global_form] 
 */
function bindLinkGlobalForm() {
  $(document).on('click', '.link_global_form', function(e) {
    e.preventDefault();

    url = $(this).attr('modal-url');
    rights = $(this).attr('modal-rights');
    title = $(this).attr('modal-title');

    var modal_selgrid = $(this).attr('modal_selgrid');
    var modal_callback = $(this).attr('modal-callback'); 
    var sw_all_if_nosel = $(this).attr('modal_all_if_nosel') //  param qui permet d'indiquer qu'on veut toutes les row si aucune selection  
    var sw_max_sel = $(this).attr('modal_max_sel') //  param qui permet de limiter le nombre de sélection
    var sel_grid = "#grid";
    
    // si on est sur du multi row
    if(modal_selgrid) {
      let qteSelRows = $(sel_grid).bootgrid('getSelectedRows').length;

      if((qteSelRows && sw_max_sel && qteSelRows > sw_max_sel) || (!qteSelRows && sw_all_if_nosel && $(sel_grid).bootgrid('getRows').length > sw_max_sel)) {
        $.toast({icon: 'error', text: `Vous ne pouvez selectionner que ${sw_max_sel} ${'élément'.plural(sw_max_sel, 'éléments')}` });
        return;
      } 

      if(qteSelRows || sw_all_if_nosel) {
        if(qteSelRows) {
          master_id = $(sel_grid).bootgrid('getSelectedRows');
        }
        else {
          master_id = _.map($(sel_grid).bootgrid('getRows'), 'rid');
        }

        affGlobalForm(url, rights, title+' ('+ master_id.length +' éléments)', JSON.stringify(master_id), null, modal_callback);
      }
      else {
        $.toast({icon: 'error', text: 'Aucune ligne sélectionnée' });
      }
    }
    else {
      affGlobalForm(url, rights, title, undefined, undefined, modal_callback);
    }
  });     
}

/**
 * [bindLinkExternalForm Action d'ouverture de la modal w_releve sur un lien de class .link_external_form] 
 */
function bindLinkExternalForm() {
  $(document).on('click', '.link_external_form', function(e) {
    e.stopPropagation();

    url = $(this).attr('modal-url');
    var newWin = window.open('about:blank', "w_releve", `height=${window.screen.height},width=${Math.max(768, window.screen.width / 3)}, 
                             top=50, left=${window.screen.width / 3*2}`);
   
    $.ajax({
      type: 'GET',
      url: url,
    }).done(function(data) {
      newWin.document.write(`<html>
                                <link href="${elixir_path_app_css}" media="all" rel="stylesheet" type="text/css" />
                                \x3Cscript type="text/javascript" src="${elixir_path_app_js}">\x3C/script>
                                \x3Cscript type="text/javascript" src="${elixir_path_vue_js}">\x3C/script>
                                  <div id="sub_edit_grid_releves">
                                    ${data}
                                  </div>
                            </html>`);

        newWin.focus();
    });
  });     
}


/**
 *  Gestion du submit de la form principale
 */
function registerSubmit(modal, grid_id, master_id, callback = null) {
  // action store / update, on capture l'action submit et on gère, ok , fail, problème validaiton
  $('#form_'+grid_id).autosubmit(
    function(data) { // ok
      if($("#form_"+grid_id).find("#submit").hasClass('js_close_on_save')) {
        window.close();
      }

      if (data.blankContent) {
        var newWin = window.open("", "Title", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=850, height=600, top=10, left=10");
        if(!newWin) {
          $.toast({icon: 'error', text: `Impossible d'ouvrir une fenêtre, merci de vérifier si vous avez accepté les popups`, hideAfter : false});
          return;
        }
        else {
          newWin.document.write(data.blankContent);
          newWin.document.close();
        }
      }

      $("#form_"+grid_id).showFormErrors({});
      msg_success = $(modal).find("form").attr('msg_success');
      if (msg_success === undefined) msg_success = 'Fiche enregistrée';
      if (msg_success) $.toast({icon: 'success', text: msg_success});
      
      if (data.gg) gg();

      // maj de certaines données
      if(data.reloadDatas) $.each(data.reloadDatas, function(index, item) {
        $(modal).find(".js_reload_data_" + index).html(item).change();
      })

      // on déclenche un event pour qui veut bien l'écouter en publiant les clés.
      $("#form_"+grid_id).trigger('submitReturnKeys', {keys:data.keys, master_id: master_id});

      // option qui lance le rechargement de la form après sauvegarde (simplifie le remplissage des données complexes)
      if($("#form_"+grid_id).find("#submit").hasClass('js_reload_on_save')) {
        for (var prop in data.keys) {
          if(typeof grid !== 'undefined') openForm(data.keys[prop], $(grid));
          $(".modal_group_tab").find(".modal_tab").first().click();
          break;
        }
      }
      else {
        // si on a des onglet on laisse la form ouverte
        if ($(modal).find(".target").length > 0) {        
          $.each(data.keys, function(index, item) {
            $('#form_crud_grid').find("#" + index).val(item);    
                        
						$.each($(modal).find(".target"), function(index, element) {
              // on check si le contenu est un json si oui on va chercher le contenu et on change la clé master_id 
              // et on replace le json dans l'attribu
							if(hasJsonStructure($(element).attr("master_id"))) {
                let masterIdJson = JSON.parse($(element).attr("master_id"));
                masterIdJson.master_id = item;
                $(element).attr("master_id", JSON.stringify(masterIdJson));
              }
              else {
                $(element).attr("master_id", item); // on part du principe qu'un form général a une clé non composite	
              }
            });
					});
        } else {
          if (!$(modal).find("form").hasClass('no-close')) $(modal).modal('hide');
        }
      }

      // on appelle la fonction callback si elle a été donnée
      if (callback && typeof callback === 'function') { 
        callback(data);
      }
      else if (callback && typeof callback === 'string') {
        window[callback](data);
      }
    },
    function(data) { // fail
      $.toast({icon: 'error', text: 'Erreur de mise à jour :<br>' + data, hideAfter : false});
    },
    function(data) { // validation
      $("#form_"+grid_id).showFormErrors(data.responseJSON);
      $.toast({icon: 'warning', text: 'Formulaire invalide'});
    }
  );
}

function init_main_search( action, view_var ) {

   /** gestion de la recherche auto sans devoir focus l'input de recherche */
      $('#search').val(''); 
      $("#main_search").show();

      $('#main_search #js_search_show').on('click', function() {
        $('#search').show("fast");
        $('#search').focus();
      })

      $(document).on('click', "#main_search #js_search_empty", function() { 
        $('#search').val(''); 
        $('#search').change();
        $('#search').hide("fast");
      });
      
      $(document).on('keydown',function(e) {
        if (!$(':focus').is('input') && !$(':focus').is('textarea')) {

          if(e.ctrlKey && e.which == 70) {
            e.preventDefault();
          }

          var regex = new RegExp("^[a-zA-Z0-9]+$");
          var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
          if (!regex.test(str)) {
            return true;
          }

          $('.itab_search').css('font-size', '25px');
          $('.itab_search').find('input').css('font-size', '25px');
          $('#main_search #search').show("fast");
          $('#main_search #search').focus();
        } 
      });

      $('#main_search #search').on('focusout', function() {
        if( !$(this).val() ) $(this).hide("fast");

        $('.itab_search').css('font-size', '14px');
        $('.itab_search').find('input').css('font-size', '14px');

      });

      var search_time = null;
      $('#main_search #search').on('change keyup copy paste cut', function(event) {

        if (event.keyCode == 27) {
          $('#main_search #search').val(''); 
          $('#main_search #search').blur();
          $('#main_search #search').hide("fast");
        }
        var filtre = $('#main_search #search').val().toLowerCase();

        clearTimeout(search_time);
        $(document).find(".js_itab_loading").show();
        search_time = setTimeout(function() {
          setTimeout(function() {
            var count = action(filtre);
            $(document).find(".js_itab_loading").hide();
            $('#main_search .js_search_info').html( count ? '('+count+')' : '' );
            $(window).trigger('search_done');
          }, 50);

        }, 250);
      });

}
  

/**
 * [gg congratulation de l'utilisateur]
 */
function gg() {
  $.toast({icon: 'info', text: 'Vous êtes un vrai forçat du travail ! Vous devriez faire une pause.'});
}

/**
 * Passage de l'écran en fullscreen avec hide des menu
 */
function cancelFullScreen(el) {
  var requestMethod = el.cancelFullScreen||el.webkitCancelFullScreen||el.mozCancelFullScreen||el.exitFullscreen;
  if (requestMethod) { // cancel full screen.
      requestMethod.call(el);
  } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
      var wscript = new ActiveXObject("WScript.Shell");
      if (wscript !== null) {
          wscript.SendKeys("{F11}");
      }
  }
}

function requestFullScreen(el) {
    // Supports most browsers and their versions.
    var requestMethod = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;

    if (requestMethod) { // Native full screen.
        requestMethod.call(el);
    } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
        var wscript = new ActiveXObject("WScript.Shell");
        if (wscript !== null) {
            wscript.SendKeys("{F11}");
        }
    }
    return false;
}

function toggleFullScreen() {
    var elem = document.documentElement; // Make the body go full screen.
    var isInFullScreen = (document.fullScreenElement && document.fullScreenElement !== null) ||  (document.mozFullScreen || document.webkitIsFullScreen);

    if (isInFullScreen) {
      cancelFullScreen(document);
      changeHandler(false);
    } else {
      requestFullScreen(elem);
      changeHandler(true);
    }

    return false;
}

function changeHandler(forceFull)
{
    // var isInFullScreen = (document.fullScreenElement && document.fullScreenElement !== null) ||  (document.mozFullScreen || document.webkitIsFullScreen);
    
    if (forceFull) {
      $(".js_hide_in_fullscreen").hide();
      $(".navbar").addClass('invo');
      $("#route").addClass('invo');
      $(".itab_bottom").removeClass('invo');
      $("body").attr('init_padding', $("body").css('padding-top'));
      $("body").css('padding-top', '10px');
      $(".container").attr('style', 'width: 100%');
      $(".grid-container").attr('style', 'max-width: 95%');

      // $("#fix_header").trigger("sticky_kit:detach");
      // $("#fix_header").sticky({ offset_top: 20 });
    }
    else {
      $(".js_hide_in_fullscreen").show();
      $(".navbar").removeClass('invo');
      $("#route").removeClass('invo');
      $(".itab_bottom").addClass('invo');
      $("body").css('padding-top', $("body").attr('init_padding'));        
      $(".container").removeAttr('style');
      $(".grid-container").removeAttr('style');

      // $("#fix_header").trigger("sticky_kit:detach");
      // $("#fix_header").sticky({ offset_top: menu_height+28 });
    }
}

function checkWH(){
    if((window.outerWidth-screen.width) ==0 && (window.outerHeight-screen.height) ==0 )
    {
      changeHandler(true);
    }
    else {
      changeHandler(false); 
    }
}

$(window).keypress(function(event){
    var code = event.keyCode || event.which;
    if(code == 122){
        setTimeout(function(){checkWH();},1000);
    }
});


function apply_type_filter(value_type, input_to_change, keep_value) {
    
  let value = keep_value?input_to_change.html():null;

  // on transforme par défaut le champs en un input text
  // on ne sait pas d'ou on vient, car le choix du champs se fait via une drop down (cas ajout)
  // on ne peut donc pas savoir si on a un texarea > input ou l'inverse.
  $text = $("<input></input>").attr({
    id: input_to_change.attr('id'),
    name: input_to_change.attr('name'),
    class: input_to_change.attr('class'),
    readonly: input_to_change.attr('readonly'),
    type: 'text',
    value: value
  });
  
  // on ajoute le champ après et on supprime le champ d'origine
  // on ne peut pas changer le type d'un champs de input a textarea sans cette manip
  // permet le destroy des event et pattern particulier par la même occasion
  input_to_change.after($text);
  input_to_change.remove();
  
  if(value_type == 'number') {
    $text.attr('pattern', '^\\d*\\.?\\d*$');
    $text.attr('title', 'Saisir un nombre');
  }
  else if(value_type == 'date') {
    $text.datetimepicker({
      locale: 'fr',
      format: "DD/MM/YYYY",
      calendarWeeks: true,
      tooltips: fr_tooltip_datepick,
      showTodayButton: true,
      showClear: true,
      useCurrent: false,
    });
    
  }
  else if(value_type == 'textarea' || value_type == 'textarea_readonly')  {
    $textarea = $("<textarea></textarea>").attr({
      id: $text.attr('id'),
      name: $text.attr('name'),
      class: $text.attr('class'),
      style: "width: 350px; height: 100px",
      readonly: (value_type == 'textarea_readonly')?true:false
    }).html(value);
    $text.after($textarea).remove();
    $textarea.removeClass('invo');
  }

  $text.removeClass('invo');

}


/**
 * Levenshtein - calcul de similarité entre deux chaine
 */

function levenshtein(chaine1, chaine2) {
  // Longueur des deux chaînes
  const longueur1 = chaine1.length;
  const longueur2 = chaine2.length;

  // Création d'un tableau pour stocker les distances
  const distances = new Array(longueur1 + 1);
  for (let i = 0; i <= longueur1; i++) {
    distances[i] = new Array(longueur2 + 1);
  }

  // Initialisation de la première ligne et de la première colonne
  for (let i = 0; i <= longueur1; i++) {
    distances[i][0] = i;
  }
  for (let j = 0; j <= longueur2; j++) {
    distances[0][j] = j;
  }

  // Calcul des distances
  for (let i = 1; i <= longueur1; i++) {
    for (let j = 1; j <= longueur2; j++) {
      const coût = chaine1[i - 1] === chaine2[j - 1] ? 0 : 1;
      distances[i][j] = Math.min(
        distances[i - 1][j] + 1, // Insertion
        distances[i][j - 1] + 1, // Suppression
        distances[i - 1][j - 1] + coût // Substitution
      );
    }
  }

  // Retourne la distance de Levenshtein
  return distances[longueur1][longueur2];
}

function levenshteinRatio(chaine1, chaine2) {
  const distance = levenshtein(chaine1, chaine2);
  const longueurMax = Math.max(chaine1.length, chaine2.length);

  return 1 - (distance / longueurMax);
}


function push_rows_to_map(rows, _map_load = {}, sw_add = true, auto_open = true, group_by = null) {
  let map_load = {
    entity_title: _map_load['title'] || 'Export carto',
      entity_url: _map_load['entity_url'] || null,
     entity_base: _map_load['entity_base'] || null,
     entity_name: _map_load['entity_name'] || null,
      entity_ids: [],
  };

  rows.forEach((row, ind) => {

  let entity = {
    id: row['id'] || `${row['lat']}_${row['lng']}`,
    title: row['titre'] || `Ligne : ${ind}`,
    lat: row['lat'],
    lng: row['lng'],
    gps_level: "adresse",
    task_time: row['temps_acte'] || 0,
    filter: "",
  }

  let fields = [];
    Object.keys(row).forEach((header, ind) => {
      if(!['id', 'titre', 'lat', 'lng', 'icon'].includes(header))
      fields.push({
        label: header, 
        value: row[header]
      });
    });

    entity['fields'] = fields;
    map_load.entity_ids.push(entity);

  });


  var storage_map_load = null;
  if(sw_add) storage_map_load = localStorage.getObject("map_load");
  if(!storage_map_load) storage_map_load = [];
  storage_map_load = _.concat(storage_map_load, map_load);
  localStorage.setObject("map_load", storage_map_load);  

  if(!auto_open) {
    $.toast({icon: 'info', text: rows.length+' points ajoutés au panier carto'});
  }
  else {
    window.open(`${map_route}/local_storage`, '_blank' ) ;
  }

}